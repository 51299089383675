import {
  DataGridPro,
  DataGridProProps,
  GridActionsCellItem,
  GridColDef as GridColDefMui,
  GridColumnMenu,
  GridColumnMenuProps,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
export * from './style';
export * as gridUtils from './utils';

type SupportedDataGridProps =
  // initialState can be used to set initial sorting
  // https://mui.com/x/react-data-grid/sorting/#initialize-the-sort-model
  | 'initialState'
  | 'apiRef'
  | 'getRowId'
  | 'getRowSpacing'
  | 'rows'
  | 'rowHeight'
  | 'loading'
  | 'sx'
  | 'onRowClick'
  | 'columnVisibilityModel'
  | 'disableRowSelectionOnClick'
  | 'disableMultipleRowSelection'
  | 'keepNonExistentRowsSelected'
  | 'getRowClassName'
  | 'disableColumnMenu'
  | 'onColumnVisibilityModelChange'
  // Use slots to override built-in components (e.g. 'not found' case) or icons
  | 'slots'
  | 'slotProps'
  // select specific props
  | 'checkboxSelection'
  | 'isRowSelectable'
  | 'rowSelectionModel'
  | 'onRowSelectionModelChange'
  // sort props
  | 'onSortModelChange'
  | 'sortingMode'
  | 'sortingOrder'
  // infinite scroll props
  | 'onRowsScrollEnd'
  | 'rowCount'
  | 'scrollEndThreshold';

type SupportedColumnProps =
  | 'field'
  | 'headerName'
  | 'headerClassName'
  | 'cellClassName'
  | 'description'
  | 'width'
  | 'flex'
  | 'minWidth'
  | 'maxWidth'
  | 'sortable'
  | 'sortingOrder'
  | 'pinnable'
  | 'sortComparator' // Custom sorting
  | 'type'
  | 'align'
  | 'valueGetter'
  | 'valueFormatter'
  | 'renderCell'
  | 'renderHeader'
  | 'headerAlign'
  | 'hideSortIcons'
  | 'getActions'
  | 'hideable';

type PickProps<T, K> = T extends unknown ? Pick<T, K & keyof T> : never;

export type GridColDef<
  R extends GridValidRowModel = any,
  V = any,
  F = V
> = PickProps<GridColDefMui<R, V, F>, SupportedColumnProps>;

export interface DataGridProps<R extends GridValidRowModel = any>
  extends Pick<DataGridProProps<R>, SupportedDataGridProps> {
  columns: GridColDef<R>[];
  // Custom prop for applying small data grid styles
  // e.g. for using in dialogs
  isSmall?: boolean;
}

const DataGrid = <R extends GridValidRowModel = any>({
  sortingMode = 'server',
  sortingOrder = ['desc', 'asc'],
  isSmall = false,
  ...props
}: DataGridProps<R>) => (
  <DataGridPro
    className={isSmall ? 'small-data-grid' : ''}
    rowHeight={isSmall ? 32 : undefined}
    columnHeaderHeight={isSmall ? 36 : 56}
    disableColumnResize
    disableColumnReorder
    disableColumnMenu
    disableMultipleColumnsSorting
    disableRowSelectionOnClick={
      props.disableRowSelectionOnClick || !props.onRowClick
    }
    hideFooter
    sortingMode={sortingMode}
    sortingOrder={sortingOrder}
    {...props}
  />
);

// Used for column menu with only hide/show columns option.
// Can be extended later, if needed.
// The list of all possible menu items: https://mui.com/x/react-data-grid/column-menu/#default-column-menu-items
const ColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuFilterItem: null,
        columnMenuSortItem: null,
        columnMenuPinningItem: null,
      }}
    />
  );
};

export { DataGrid, GridActionsCellItem, useGridApiRef, ColumnMenu };

export type {
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  GridRowsProp,
  GridSortModel,
  GridColumnVisibilityModel,
  GridRowSelectionModel,
  GridAlignment,
  GridApiPro,
  GridColumnMenuProps,
} from '@mui/x-data-grid-pro';

// use this placeholder for all column sets, when page has details view.
export const drawerPlaceholderColumn = {
  field: 'drawerPlaceholder',
  hideable: false,
  headerName: '',
  width: 600 - 5 * 8, // DetailsDrawer width - PageTableContent left margin
  sortable: false,
};
