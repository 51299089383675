import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useSuppliersEnabled } from 'domains/transaction/hooks';
import { MISSING_SUPPLIER_OPTION } from 'domains/transaction/pages/ExportPage/utils';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { AccountingItemStatus, Supplier } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { SupplierType } from './index';

const getInvalidSupplierMock = (text: string) => ({
  id: 'invalid',
  name: text,
  accountNumber: null,
  status: AccountingItemStatus.inactive,
  organizationId: '',
});

export interface PreselectedDataState {
  supplier: SupplierType;
  supplierInvalid: boolean;
  areFiltersLoading: boolean;
}

export const initialPreselectedDataState: PreselectedDataState = {
  supplier: null,
  supplierInvalid: false,
  areFiltersLoading: false,
};

interface UsePreselectedData {
  preselectedData: PreselectedDataState;
  onPreselectedChange: (updatedState: Partial<PreselectedDataState>) => void;
}

const usePreselectedData = (paramSupplier: string): UsePreselectedData => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const areSuppliersEnabled = useSuppliersEnabled();
  const [preselectedData, setPreselectedData] = useState<PreselectedDataState>({
    supplier: null,
    supplierInvalid: false,
    areFiltersLoading: !!paramSupplier && areSuppliersEnabled,
  });

  const getSupplier = async () => {
    if (paramSupplier === MISSING_SUPPLIER_OPTION)
      return {
        supplier: MISSING_SUPPLIER_OPTION as SupplierType,
        supplierInvalid: false,
      };
    try {
      const supplier = await api.getSupplier(paramSupplier);
      return { supplier, supplierInvalid: false };
    } catch (error) {
      // This BE endpoint should be improved and return 404 even if incorrect uuid is passed
      // (the same way the other similar endpoints do)
      if (
        error instanceof AxiosError &&
        error.response &&
        [400, 404].includes(error.response.status)
      )
        return {
          supplier: getInvalidSupplierMock(t('filters.notFound')),
          supplierInvalid: true,
        };

      throw error;
    }
  };

  // This function will be extended and improved, when adding other dynamic filters.
  const getDynamicFilterData = async () => {
    setPreselectedData((prevState) => ({
      ...prevState,
      areFiltersLoading: true,
    }));

    try {
      const { supplier, supplierInvalid } = await getSupplier();
      if (!mounted.current) return;
      setPreselectedData((prevState) => ({
        ...prevState,
        areFiltersLoading: false,
        supplier,
        supplierInvalid,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setPreselectedData((prevState) => ({
        ...prevState,
        areFiltersLoading: false,
        supplier: getInvalidSupplierMock(t('filters.notFound')),
        supplierInvalid: true,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), {
        variant: 'error',
      });
      logError(error);
    }
  };

  useEffect(() => {
    if (!paramSupplier || !areSuppliersEnabled) return;

    // Refetch information only when param differs from stored value.
    // It covers initial loading and back/forward navigation cases.
    if (
      !preselectedData.supplier ||
      (String(preselectedData.supplier) !== paramSupplier &&
        (preselectedData.supplier as Supplier)?.id !== paramSupplier)
    )
      getDynamicFilterData();
  }, [paramSupplier]);

  return {
    preselectedData,
    onPreselectedChange: (updatedState) =>
      setPreselectedData((prevState) => ({ ...prevState, ...updatedState })),
  };
};

export default usePreselectedData;
