import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, TextField, Typography } from 'elements';

const StepUpAuthSection = () => {
  const { loginWithRedirect } = useAuth0();
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');

  return (
    <Box
      mb={2}
      pt={2}
      borderTop={(theme) => `1px solid ${theme.palette.divider}`}
    >
      <Typography variant="subtitle2" mb={1}>
        Auth0 Step-up Authentication
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={2}>
        Enforce Auth0 step-up authentication and optionally send a custom
        parameter to the Authorization Server.
      </Typography>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          loginWithRedirect({
            scope: 'mfa:required',
            ...(!!key.trim() && !!value.trim()
              ? { [key.trim()]: value.trim() }
              : {}),
            redirectUri:
              window.location.origin +
              `/callback?3dsAuthRequest=${encodeURIComponent(
                JSON.stringify({
                  gpsInitiateActionId: 'e67b162a-f99e-4f4d-ab8d-f53f2480e5ab',
                  pmReferenceId: 'a1470c23-bd8b-4ba9-a702-dc146259d77e',
                  pubToken: '109943924',
                  status: 'APPROVED',
                })
              )}`,
          });
        }}
      >
        <Box>
          <TextField
            label="Key"
            value={key}
            onChange={(e) => setKey(e.target.value)}
          />
        </Box>
        <Box mt={1} mb={3}>
          <TextField
            label="Value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Box>
        <Box>
          <Button fullWidth type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default StepUpAuthSection;
