import { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  LoaderWithOverlay,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PaymentMethod, STRIPE_PUBLIC_KEY } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import Step1 from './Step1';
import Step2 from './Step2';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: (paymentMethod: PaymentMethod) => void;
}

interface State {
  isLoading: boolean;
  step: 1 | 2;
  secret: string | null;
}

const AddCardDialog = ({ onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const {
    state: { member },
  } = useGlobalState();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    isLoading: false,
    step: 1,
    secret: null,
  });

  const createStripeSetupIntent = async () => {
    try {
      const response = await api.createStripeSetupIntent(member.id);
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        secret: response.clientSecret,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const goToNextStep = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      await createStripeSetupIntent();
      setState((prevState) => ({ ...prevState, isLoading: false, step: 2 }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const onAddCardSuccess = (paymentMethod: PaymentMethod) => {
    setState((prevState) => ({
      ...prevState,
      addCardDialogOpen: false,
    }));
    onSuccess(paymentMethod);
    enqueueSnackbar(
      t('profilePage.privatePaymentCard.messages.cardAddedSuccess'),
      {
        variant: 'success',
      }
    );
  };

  return (
    <Dialog {...props}>
      <LoaderWithOverlay loading={state.isLoading} />
      <DialogTitle>
        {t('profilePage.privatePaymentCard.addCardDialog.title')}
      </DialogTitle>
      {state.step === 1 && (
        <Step1 onClose={props.onClose} onSuccess={goToNextStep} />
      )}
      {state.step === 2 && state.secret && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: state.secret,
            appearance: {
              variables: {
                fontFamily: 'MaisonNeue, sans-serif',
              },
            },
          }}
        >
          <Step2 onClose={props.onClose} onSuccess={onAddCardSuccess} />
        </Elements>
      )}
    </Dialog>
  );
};

export default withDialogWrapper<Props>(AddCardDialog);
