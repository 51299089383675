import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { AppWrapper } from 'components/App/AppWrapper';
import QaTools from 'components/QaTools';
import { env } from 'env';
import { onAuth0RedirectCallback } from 'services/utils';
import Routes from './Routes';

const urlParams = new URLSearchParams(window.location.search);

export const ExternalApp = () => {
  return (
    <AppWrapper>
      <Auth0Provider
        domain={env.REACT_APP_AUTH0_DOMAIN}
        clientId={env.REACT_APP_AUTH0_CLIENT_ID}
        audience={env.REACT_APP_AUTH0_AUDIENCE}
        redirectUri={window.location.origin + '/callback'}
        onRedirectCallback={onAuth0RedirectCallback}
        // custom URL parameters which are passed to the login page
        language={urlParams.get('language') || undefined}
        inactivityLogout={urlParams.get('inactivityLogout') || undefined}
        emailVerification={urlParams.get('emailVerification') || undefined}
        passwordReset={urlParams.get('passwordReset') || undefined}
        registration={urlParams.get('registration') || undefined}
      >
        <Routes />
        {env.REACT_APP_TARGET_ENV !== 'prod' && <QaTools />}
      </Auth0Provider>
    </AppWrapper>
  );
};
