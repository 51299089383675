import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

interface State {
  isLoading: boolean;
  alreadyAcceptedTermsAndConditions: boolean;
  acceptedTermsAndConditions: boolean;
}

const Step1 = (props: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const {
    state: { member, organization },
  } = useGlobalState();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    isLoading: true,
    alreadyAcceptedTermsAndConditions: false,
    acceptedTermsAndConditions: false,
  });

  useEffect(() => {
    checkCardholderReimbursementTermsAndConditions();
  }, []);

  const checkCardholderReimbursementTermsAndConditions = async () => {
    try {
      const response = await api.checkCardholderReimbursementTermsAndConditions(
        member.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        alreadyAcceptedTermsAndConditions: response.isAccepted,
        acceptedTermsAndConditions: response.isAccepted,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const acceptTermsAndConditionsOrContinue = async () => {
    if (!state.alreadyAcceptedTermsAndConditions) {
      try {
        await api.acceptCardholderReimbursementTermsAndConditions(
          member.id,
          organization!.id
        );
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    }
    props.onSuccess();
  };

  return (
    <>
      <DialogContent>
        <Typography component="div" mb={2}>
          {t('profilePage.privatePaymentCard.addCardDialog.description')}
        </Typography>
        {!state.isLoading && !state.alreadyAcceptedTermsAndConditions && (
          <FormControlLabel
            control={
              <Checkbox
                checked={state.acceptedTermsAndConditions}
                onChange={() =>
                  setState((prevState) => ({
                    ...prevState,
                    acceptedTermsAndConditions: !prevState.acceptedTermsAndConditions,
                  }))
                }
              />
            }
            label={
              <Typography variant="body2" color="text.secondary">
                <Trans
                  i18nKey="profilePage.privatePaymentCard.addCardDialog.termsAndConditions"
                  components={{
                    a: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        style={{ color: 'inherit' }}
                        href="asd"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                  }}
                />
              </Typography>
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="text"
          children={t('common.button.cancel')}
        />
        <Button
          onClick={acceptTermsAndConditionsOrContinue}
          disabled={!state.acceptedTermsAndConditions}
          children={t('common.button.confirm')}
        />
      </DialogActions>
    </>
  );
};

export default Step1;
