import { Chip, ChipProps } from '@mui/material';
import { CircleNotchFilledIcon } from 'elements/TemporaryIcon';

export interface LoadingChipProps extends ChipProps {
  loading?: boolean;
}

export const LoadingChip = ({ loading, ...props }: LoadingChipProps) => {
  return (
    <Chip
      deleteIcon={loading ? <CircleNotchFilledIcon /> : undefined}
      onDelete={(e) => {
        if (loading) return;
        props.onDelete && props.onDelete(e);
      }}
      sx={{
        ...(loading
          ? {
              '.MuiChip-deleteIcon': {
                pointerEvents: 'none',
                animation: 'spin 1.2s linear infinite',
              },
              '@keyframes spin': {
                '0%': { transform: 'rotate(0deg)' },
                '100%': { transform: 'rotate(360deg)' },
              },
            }
          : {}),
      }}
      {...props}
    />
  );
};
