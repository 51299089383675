import { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { useSuppliersEnabled } from 'domains/transaction/hooks';
import {
  MISSING_SUPPLIER_OPTION,
  MISSING_TEAM_OPTION,
  QueryParams,
} from 'domains/transaction/pages/ExportPage/utils';
import { Chip, LoadingChip, Tooltip } from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { getFilterChipText, PageFilterChips } from 'layout';
import {
  NONE_VALUE,
  ProjectStatus,
  SubcategoryStatus,
  VatRateStatus,
} from 'services/constants';
import { PreselectedDataState } from './usePreselectedData';

interface Props {
  params: QueryParams;
  setParam: SetQueryParam;
  selectedFiltersCount: number;
  preselectedData: PreselectedDataState;
  onPreselectedReset: (data: Partial<PreselectedDataState>) => void;
}

const FilterChips = ({
  params,
  selectedFiltersCount,
  preselectedData,
  onPreselectedReset,
  setParam,
}: Props) => {
  const { t } = useTranslation();
  const areSuppliersEnabled = useSuppliersEnabled();
  const {
    state: {
      cardAccounts,
      subcategories,
      featureModules,
      accountingSettings,
      vatRates,
      projects,
      teams,
    },
  } = useGlobalState();
  const accountingFeaturesEnabled = featureModules.ACCOUNTING_FEATURES;

  const txSimpleTypeChipTooltip = useMemo(() => {
    if (params.type.length < 2) return '';
    return params.type
      .map((item) => t(`transactionSimpleTypes.${item}`))
      .join(', ');
  }, [params.type]);

  const supplierChipText = useMemo(() => {
    if (preselectedData.areFiltersLoading) return t('filters.loading');
    if (preselectedData.supplierInvalid) return t('filters.notFound');
    if (preselectedData.supplier === MISSING_SUPPLIER_OPTION) return '-';
    return preselectedData.supplier?.name || '';
  }, [
    preselectedData.supplier,
    preselectedData.areFiltersLoading,
    preselectedData.supplierInvalid,
  ]);

  const subcategoryChipText = useMemo(() => {
    if (params.subcategory === SubcategoryStatus.missing) return '-';
    return (
      subcategories?.find((item) => item.id === params.subcategory)?.name || ''
    );
  }, [params.subcategory]);

  const vatRateChipText = useMemo(() => {
    if (params.vatRate === VatRateStatus.missing) return '-';
    return vatRates?.find((item) => item.id === params.vatRate)?.name || '';
  }, [params.vatRate, vatRates]);

  const projectChipText = useMemo(() => {
    if (params.project === ProjectStatus.missing) return '-';
    return (
      projects?.find((project) => project.id === params.project)?.name || ''
    );
  }, [params.project, projects]);

  const teamChipText = useMemo(() => {
    if (params.team === MISSING_TEAM_OPTION) return '-';
    return teams?.find((team) => team.id === params.team)?.name || '';
  }, [params.team, teams]);

  const periodChipText = useMemo(() => {
    if (!params.fromDate && !params.toDate) return '';
    const array = [];
    if (params.fromDate)
      array.push(moment(params.fromDate).format('D MMM YYYY'));
    if (params.toDate) array.push(moment(params.toDate).format('D MMM YYYY'));
    return array.join(' - ');
  }, [params.fromDate, params.toDate]);

  const reviewStatusChipText = useMemo(() => {
    if (!params.reviewStatus) return '';
    if (params.reviewStatus === NONE_VALUE) return t(`common.none`);
    return t(`transactionReviewStatus.${params.reviewStatus}`);
  }, [params.reviewStatus]);

  const cardAccountsChipText = useMemo(
    () =>
      cardAccounts?.find((account) => account.id === params.cardAccountId)?.name
        ?.value || '',
    [params.cardAccountId, cardAccounts]
  );

  if (!selectedFiltersCount) return null;

  return (
    <PageFilterChips>
      {!!params.type.length && (
        <Tooltip title={txSimpleTypeChipTooltip}>
          <Chip
            label={getFilterChipText(
              t(`transactionSimpleTypes.${params.type[0]}`),
              params.type.length
            )}
            onDelete={() => setParam('type', [])}
          />
        </Tooltip>
      )}

      {(params.fromDate || params.toDate) && (
        <Chip
          label={periodChipText}
          onDelete={() =>
            setParam([
              ['toDate', ''],
              ['fromDate', ''],
            ])
          }
        />
      )}

      {cardAccounts.length > 1 && params.cardAccountId && (
        <Chip
          label={getFilterChipText(
            cardAccountsChipText,
            1,
            t('filters.account')
          )}
          onDelete={() => setParam('cardAccountId', '')}
        />
      )}

      {!!params.supplier && areSuppliersEnabled && (
        <LoadingChip
          loading={preselectedData.areFiltersLoading}
          color={preselectedData.supplierInvalid ? 'error' : 'default'}
          variant={preselectedData.supplierInvalid ? 'tinted' : 'filled'}
          label={getFilterChipText(supplierChipText, 1, t('filters.supplier'))}
          onDelete={() => {
            setParam('supplier', '');
            onPreselectedReset({ supplier: null, supplierInvalid: false });
          }}
        />
      )}

      {featureModules.RECEIPT_MANAGEMENT && !!params.receipt && (
        <Chip
          label={getFilterChipText(
            t(`transactionReceiptStatuses.${params.receipt}`),
            1,
            t('filters.receipts')
          )}
          onDelete={() => setParam('receipt', '')}
        />
      )}

      {accountingSettings?.subcategoryEnabled &&
        !!params.subcategory &&
        !!subcategories?.length && (
          <Chip
            label={getFilterChipText(
              subcategoryChipText,
              1,
              t('filters.subcategory')
            )}
            onDelete={() => setParam('subcategory', '')}
          />
        )}

      {!!vatRates?.length &&
        accountingSettings?.vatRateEnabled &&
        !!params.vatRate && (
          <Chip
            label={getFilterChipText(vatRateChipText, 1, t('filters.vatRate'))}
            onDelete={() => setParam('vatRate', '')}
          />
        )}

      {!!params.team && !!teams.length && featureModules.TEAMS && (
        <Chip
          label={getFilterChipText(teamChipText, 1, t('filters.team'))}
          onDelete={() => setParam('team', '')}
        />
      )}

      {!!params.project &&
        !!projects?.length &&
        accountingFeaturesEnabled &&
        accountingSettings?.projectEnabled && (
          <Chip
            label={getFilterChipText(projectChipText, 1, t('filters.project'))}
            onDelete={() => setParam('project', '')}
          />
        )}

      {params.reviewStatus && (
        <Chip
          label={reviewStatusChipText}
          onDelete={() =>
            setParam([
              ['reviewStatus', ''],
              ['flagReason', ''],
            ])
          }
        />
      )}

      {params.flagReason && (
        <Chip
          label={t(`reviewFlagReasons.${params.flagReason}`)}
          onDelete={() => setParam('flagReason', '')}
        />
      )}
    </PageFilterChips>
  );
};

export default FilterChips;
