import xor from 'lodash/xor';
import { useGlobalState } from 'context/GlobalState';
import { CardAccountFilter } from 'domains/card/components';
import {
  FlagReasonFilter,
  ReceiptFilter,
  ReviewFilter,
  TransactionSimpleTypeFilter,
} from 'domains/transaction/components';
import { useSuppliersEnabled } from 'domains/transaction/hooks';
import {
  QueryParams,
  visibleTransactionTypes,
} from 'domains/transaction/pages/ExportPage/utils';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import { TransactionReviewStatus } from 'services/constants';
import ProjectFilter from './ProjectFilter';
import SubcategoryFilter from './SubcategoryFilter';
import SupplierFilter from './SupplierFilter';
import TeamsFilter from './TeamsFilter';
import VatRateFilter from './VatRateFilter';
import { FiltersState } from './index';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  params: QueryParams;
  filtersState: FiltersState;
  supplierHasError: boolean;
  setParam: SetQueryParam;
  onClose: () => void;
  onFiltersApply: () => void;
  onFilterReset: () => void;
  setFilters: (filters: Partial<FiltersState>) => void;
}

const FiltersDrawer = ({
  params,
  filtersState,
  supplierHasError,
  onFiltersApply,
  onFilterReset,
  setFilters,
  setParam,
  ...props
}: Props) => {
  const areSuppliersEnabled = useSuppliersEnabled();
  const {
    state: { accountingSettings, cardAccounts, featureModules },
  } = useGlobalState();
  const accountingFeaturesEnabled = featureModules.ACCOUNTING_FEATURES;

  const areFiltersSelected = !!(
    filtersState.type.length ||
    filtersState.receipt ||
    filtersState.supplier ||
    filtersState.subcategory ||
    filtersState.vatRate ||
    filtersState.project ||
    filtersState.team ||
    filtersState.fromDate ||
    filtersState.toDate ||
    filtersState.reviewStatus ||
    filtersState.flagReason ||
    filtersState.cardAccountId
  );

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <TransactionSimpleTypeFilter
        value={filtersState.type}
        onChange={(value) =>
          setFilters({ type: xor(filtersState.type, [value]) })
        }
        options={visibleTransactionTypes}
      />

      <DateFilter
        fromDate={params.fromDate}
        toDate={params.toDate}
        onChange={(fromDate, toDate) =>
          setFilters({
            fromDate: fromDate?.startOf('day') || null,
            toDate: toDate?.endOf('day') || null,
          })
        }
      />

      {cardAccounts.length > 1 && (
        <CardAccountFilter
          value={filtersState.cardAccountId}
          onChange={(value) => setFilters({ cardAccountId: value })}
        />
      )}

      {areSuppliersEnabled && (
        <SupplierFilter
          value={filtersState.supplier}
          hasError={supplierHasError}
          onChange={(value) => {
            setFilters({ supplier: value });
          }}
        />
      )}

      {featureModules.RECEIPT_MANAGEMENT && (
        <ReceiptFilter
          value={filtersState.receipt}
          onChange={(value) => setFilters({ receipt: value })}
        />
      )}

      {accountingSettings?.subcategoryEnabled && (
        <SubcategoryFilter
          value={filtersState.subcategory}
          onChange={(value) => setFilters({ subcategory: value })}
        />
      )}

      {accountingSettings?.vatRateEnabled && (
        <VatRateFilter
          value={filtersState.vatRate}
          onChange={(value) => setFilters({ vatRate: value })}
        />
      )}

      {featureModules.TEAMS && (
        <TeamsFilter
          value={filtersState.team}
          onChange={(value) => setFilters({ team: value })}
        />
      )}

      {accountingFeaturesEnabled && accountingSettings?.projectEnabled && (
        <ProjectFilter
          value={filtersState.project}
          onChange={(value) => setFilters({ project: value })}
        />
      )}

      <ReviewFilter
        value={filtersState.reviewStatus}
        onChange={(value) => setFilters({ reviewStatus: value })}
      />

      <FlagReasonFilter
        value={filtersState.flagReason}
        onChange={(flagReason) =>
          setFilters({
            flagReason,
            reviewStatus:
              filtersState.reviewStatus || TransactionReviewStatus.flagged,
          })
        }
      />
    </FilterDrawer>
  );
};

export default FiltersDrawer;
