import React, { ComponentType, FC, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { createPartnerTheme } from 'components/App/style/themeMui5';
import { useGlobalState } from 'context/GlobalState';
import { env } from 'env';
import useIdTokenCustomData from 'hooks/useIdTokenCustomData';
import usePartnerName from 'hooks/usePartnerName';
import {
  PartnerConfig,
  PartnerIds,
  PartnerWhitelabelLevel,
  SupportedWebAppLanguage,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';

const getAssetsUrl = (partnerConfig: PartnerConfig) => {
  if (
    [
      PartnerWhitelabelLevel.FULLY_WHITELABEL,
      PartnerWhitelabelLevel.EMBEDDED_WHITELABEL,
    ].includes(partnerConfig.whitelabelLevel) &&
    partnerConfig?.partnerId !== PartnerIds.pliant
  ) {
    return `${env.REACT_APP_ASSETS_URL}/images/partners/${partnerConfig!.name
      .toLowerCase()
      .replace(/[^a-z0-9]/gi, ' ')
      .replaceAll(/\s+/g, '-')}/`;
  }
  if (env.REACT_APP_TARGET_ENV === 'development') {
    return `${env.REACT_APP_ASSETS_URL}/images/dev/`;
  }
  if (env.REACT_APP_TARGET_ENV === 'staging') {
    return `${env.REACT_APP_ASSETS_URL}/images/staging/`;
  }

  return `${env.REACT_APP_ASSETS_URL}/images/v2/`;
};

/**
 * This HOC sets partner information
 * and white label flags in the global state.
 */
const withPartnerLogic = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element | null => {
  const { i18n } = useTranslation();
  const api = useImperativeApi();
  const canUser = useCanUser();
  const partnerName = usePartnerName();
  const {
    dispatch,
    state: { organization, partnerConfig, themeId },
  } = useGlobalState();
  const { organizationId } = useIdTokenCustomData();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useMemo(
    () => createPartnerTheme(themeId, i18n.language as SupportedWebAppLanguage),
    [themeId, i18n.language]
  );

  useEffect(() => {
    (async () => {
      try {
        const [
          partnerList,
          partnerOrgAuthDetails,
          partnerConfig,
        ] = await Promise.all([
          canUser('partners:view') ? api.getPartners() : undefined,
          canUser('partners:view')
            ? api.getPartnerOrgAuthDetails(
                organization!.partnerId,
                organizationId
              )
            : undefined,
          canUser('partner-config:view')
            ? api.getPartnerConfig(organization!.partnerId)
            : undefined,
        ]);

        dispatch({
          type: 'SET_THEME_ID',
          payload:
            partnerConfig &&
            [
              PartnerWhitelabelLevel.FULLY_WHITELABEL,
              PartnerWhitelabelLevel.COMPLIANCE_RISK_WHITELABEL,
              PartnerWhitelabelLevel.EMBEDDED_WHITELABEL,
              PartnerWhitelabelLevel.LOGO_ONLY,
            ].includes(partnerConfig.whitelabelLevel)
              ? (organization!.partnerId as string)
              : PartnerIds.pliant,
        });

        dispatch({
          type: 'SET_APP_TYPE',
          payload: {
            isComplianceRiskWhiteLabelApp: Boolean(
              partnerConfig?.whitelabelLevel ===
                PartnerWhitelabelLevel.COMPLIANCE_RISK_WHITELABEL
            ),
          },
        });

        dispatch({
          type: 'SET_PARTNER_DATA',
          payload: {
            partners: partnerList?.partners,
            partnerOrgAuthDetails,
            partnerConfig,
          },
        });

        setIsLoading(false);
      } catch (error) {
        dispatch({ type: 'SET_ERROR', payload: error });
        logError(error);
      }
    })();
  }, []);

  return isLoading ? null : (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link
          type="image/x-icon"
          rel="shortcut icon"
          href={`${getAssetsUrl(partnerConfig!)}favicon.ico`}
        />
        <link
          type="image/png"
          sizes="16x16"
          rel="icon"
          href={`${getAssetsUrl(partnerConfig!)}favicon-16x16.png`}
        />
        <link
          type="image/png"
          sizes="32x32"
          rel="icon"
          href={`${getAssetsUrl(partnerConfig!)}favicon-32x32.png`}
        />
        <link
          type="image/png"
          sizes="96x96"
          rel="icon"
          href={`${getAssetsUrl(partnerConfig!)}favicon-96x96.png`}
        />
        <title>{partnerName}</title>
      </Helmet>
      <Component {...props} />
    </ThemeProvider>
  );
};

export default withPartnerLogic;
