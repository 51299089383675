import { useTranslation } from 'react-i18next';
import { ModuleTileDependency } from 'domains/settings/pages/FeatureModulesPage/useModuleTiles';
import { Box, InfoIcon, StatusBadge, Tooltip, Typography } from 'elements';

interface Props {
  data?: ModuleTileDependency[];
}

const Dependencies = ({ data }: Props) => {
  const { t } = useTranslation();

  if (!data?.length) return null;

  return (
    <Box marginTop={3}>
      <Typography variant="overline" textTransform="uppercase">
        {t('manageFeatureModuleDialog.dependentModules.title')}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary">
        {t('manageFeatureModuleDialog.dependentModules.subtitle')}
      </Typography>

      {data.map((dependency) => (
        <Box
          key={dependency.key}
          display="flex"
          justifyContent="space-between"
          marginTop={2}
          borderRadius={1}
          border={1}
          borderColor="text.secondary"
          paddingTop={1}
          paddingRight={2}
          paddingBottom={1}
          paddingLeft={2}
        >
          <Box display="flex">
            <Typography variant="subtitle2">{dependency.key}</Typography>
            <Tooltip title={dependency.tooltip} placement="right">
              <InfoIcon
                sx={{
                  width: 20,
                  height: 20,
                  ml: 0.75,
                  color: 'text.secondary',
                }}
              />
            </Tooltip>
          </Box>
          <StatusBadge
            label={
              dependency.isActive
                ? t('manageFeatureModuleDialog.active')
                : t('manageFeatureModuleDialog.deactivated')
            }
            color={dependency.isActive ? 'success' : 'primary'}
          />
        </Box>
      ))}
    </Box>
  );
};

export default Dependencies;
