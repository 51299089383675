import { useState } from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  DialogActions,
  DialogContent,
  LoaderWithOverlay,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PaymentMethod } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  onClose: () => void;
  onSuccess: (paymentMethod: PaymentMethod) => void;
}

interface State {
  isLoading: boolean;
  errorMessage?: string;
}

const Step2 = (props: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    state: { member },
  } = useGlobalState();
  const stripe = useStripe();
  const elements = useElements();
  const [state, setState] = useState<State>({
    isLoading: false,
    errorMessage: '',
  });

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return null;
    }

    setState({ ...state, isLoading: true });

    try {
      const response = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
      });

      if (response.error) {
        if (!mounted.current) return;
        setState({ ...state, errorMessage: response.error.message });
      } else if (response.setupIntent) {
        addPaymentMethod(response.setupIntent.payment_method as string);
      }
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const addPaymentMethod = async (paymentMethodId: string) => {
    try {
      const response = await api.addPaymentMethod(member.id, paymentMethodId);
      if (!mounted.current) return;
      props.onSuccess(response);
      props.onClose();
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <DialogContent>
        <PaymentElement />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="text"
          children={t('common.button.cancel')}
        />
        <Button
          onClick={handleSubmit}
          disabled={!stripe || state.isLoading}
          children={t('common.button.confirm')}
        />
      </DialogActions>

      <LoaderWithOverlay loading={state.isLoading} />
    </>
  );
};

export default Step2;
