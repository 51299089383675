import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { MISSING_SUPPLIER_OPTION } from 'domains/transaction/pages/ExportPage/utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  CaretDownIcon,
  FormControl,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import {
  AccountingItemStatus,
  DEFAULT_AUTOCOMPLETE_DELAY,
  DEFAULT_PAGE_LIMIT,
  Supplier,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { SupplierType } from './index';

const getSupplierName = (supplier: Supplier) =>
  supplier.name +
  (supplier.accountNumber ? ` (${supplier.accountNumber})` : '');

interface Props {
  value: SupplierType;
  hasError: boolean;
  onChange: (value: SupplierType) => void;
}

interface State {
  isLoading: boolean;
  suppliers: Supplier[];
}

const SupplierFilter = ({ value, hasError, onChange }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    state: { organization },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    isLoading: true,
    suppliers: [],
  });
  const [inputValue, setInputValue] = useState('');

  const getData = async () => {
    try {
      const { suppliers } = await api.getSuppliers({
        organizationId: organization!.id,
        page: 0,
        limit: DEFAULT_PAGE_LIMIT,
        q: inputValue.trim(),
        sortBy: '+name',
        status: AccountingItemStatus.active,
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        suppliers,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    let active = true;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    setTimeout(() => {
      if (active) getData();
    }, DEFAULT_AUTOCOMPLETE_DELAY);
    return () => {
      active = false;
    };
  }, [inputValue.trim()]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<CaretDownIcon />}
        aria-controls="panel-supplier"
        id="panel-supplier-header"
      >
        <Typography>{t('filters.supplier')} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <Autocomplete<SupplierType, false, false, true>
            freeSolo
            inputValue={inputValue}
            loading={state.isLoading}
            size="medium"
            onInputChange={(e, inputValue) => {
              setInputValue(inputValue);
            }}
            error={hasError}
            value={value}
            onChange={(e, value) => {
              if (
                value === MISSING_SUPPLIER_OPTION ||
                typeof value !== 'string'
              )
                return onChange(value);
            }}
            options={[MISSING_SUPPLIER_OPTION, ...state.suppliers]}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, value) => {
              if (
                option === MISSING_SUPPLIER_OPTION ||
                value === MISSING_SUPPLIER_OPTION
              )
                return value === option;
              return option?.id === value?.id;
            }}
            getOptionLabel={(option) => {
              if (!option) return '';
              if (option === MISSING_SUPPLIER_OPTION) return '—';
              return (option as Supplier).name;
            }}
            renderOption={(props, option) => {
              if (!option) return '';
              if (option === MISSING_SUPPLIER_OPTION)
                return (
                  <li {...props} key={option}>
                    —
                  </li>
                );
              return (
                <li {...props} key={option.id}>
                  <Box
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {getSupplierName(option as Supplier)}
                  </Box>
                </li>
              );
            }}
            placeholder={t('filters.supplier')}
            loadingText={t('common.loading')}
            noOptionsText={t('common.nothingFound')}
            searchIcon={false}
          />
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default SupplierFilter;
