import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CountryFlag from 'components/CountryFlag';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { TeamsCell } from 'domains/member/components';
import {
  drawerPlaceholderColumn,
  GridColDef,
  gridUtils,
  Typography,
} from 'elements';
import { OrganizationMerchant } from 'services/constants';
import GridColumnHeaderTitleWithTooltipDescription from './GridColumnHeaderTitleWithTooltipDescription';
import OrganizationMerchantCell from './OrganizationMerchantCell';

const useColumns = () => {
  const { t, i18n } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();

  return useMemo(() => {
    const columns: GridColDef<OrganizationMerchant>[] = [
      {
        headerName: t('organizationMerchantsPage.merchant'),
        field: 'displayName',
        flex: 308,
        renderCell: ({ row }) => <OrganizationMerchantCell merchant={row} />,
        hideable: false,
      },
      {
        headerName: t('organizationMerchantsPage.country'),
        field: 'country',
        flex: 90,
        renderCell: ({ row }) => (
          <>
            <CountryFlag code={row.country} mr={1} flexShrink={0} />
            <Typography variant="body2">{row.country}</Typography>
          </>
        ),
      },
      {
        headerName: t('organizationMerchantsPage.teamResponses'),
        field: 'teamResponses',
        flex: 240,
        renderCell: ({ row }) => <TeamsCell teams={row.teamResponses} />,
        sortable: false,
      },
      {
        headerName: t(
          'organizationMerchantsPage.previousMonthTransactionsVolume'
        ),
        field: 'previousMonthTransactionsVolume',
        flex: 112,
        renderCell: ({ row }) => (
          <FormatMoney
            value={row.previousMonthTransactionsVolume}
            fractionalPart
          />
        ),
        align: 'right',
        headerAlign: 'right',
        sortable: false,
      },
      {
        headerName: t('organizationMerchantsPage.currentTransactionsVolume'),
        field: 'currentTransactionsVolume',
        flex: 126,
        renderCell: ({ row }) => (
          <FormatMoney value={row.currentTransactionsVolume} fractionalPart />
        ),
        align: 'right',
        headerAlign: 'right',
        sortable: false,
      },
      {
        renderHeader: ({ colDef }) => (
          <GridColumnHeaderTitleWithTooltipDescription
            width={colDef.computedWidth}
            label={t('organizationMerchantsPage.transactions')}
            description={t('organizationMerchantsPage.transactionsDescription')}
          />
        ),
        headerName: t('organizationMerchantsPage.transactions'),
        field: 'transactionsCount',
        flex: 116,
        align: 'right',
        headerAlign: 'right',
      },
      {
        renderHeader: ({ colDef }) => (
          <GridColumnHeaderTitleWithTooltipDescription
            width={colDef.computedWidth}
            label={t('organizationMerchantsPage.totalVolume')}
            description={t('organizationMerchantsPage.totalVolumeDescription')}
          />
        ),
        headerName: t('organizationMerchantsPage.totalVolume'),
        field: 'transactionsVolume',
        flex: 116,
        renderCell: ({ row }) => (
          <FormatMoney value={row.transactionsVolume} fractionalPart />
        ),
        align: 'right',
        headerAlign: 'right',
      },
      drawerPlaceholderColumn,
    ];

    return {
      columns: columns.filter(
        (column) => !(column.field === 'teamResponses' && !featureModules.TEAMS)
      ),
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
    };
  }, [i18n.language, featureModules.TEAMS]);
};

export default useColumns;
