import { useMemo } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';

const useSuppliersEnabled = () => {
  const { suppliersEnabled } = useFlags();
  const canUser = useCanUser();
  const {
    state: { accountingSettings, featureModules },
  } = useGlobalState();

  return useMemo(
    () =>
      canUser('supplier:view') &&
      featureModules.ACCOUNTING_FEATURES &&
      suppliersEnabled &&
      !!accountingSettings?.supplierEnabled,
    [featureModules.ACCOUNTING_FEATURES, accountingSettings?.supplierEnabled]
  );
};

export default useSuppliersEnabled;
